import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './pages/home/home.component';
import { AyudaComponent } from './pages/maestros/ayuda/ayuda.component';
import { PrivacyAdviceComponent } from './pages/privacy-advice/privacy-advice.component';
import { LoggedInAuthGuard } from './_helpers/loggedIn-auth.guard';

const routes: Routes = [
  { path: '', component: HomeComponent, canActivate: [LoggedInAuthGuard] },

  { path: 'aviso-privacidad', component: PrivacyAdviceComponent },
  // Students routes
  { path: 'alumno', loadChildren: () => import('./alumnos/alumnos.module').then(m => m.AlumnosModule) },

  // Teachers routes
  { path: 'maestro', loadChildren: () => import('./maestros/maestros.module').then(m => m.MaestrosModule) },

  // Parents routes
  { path: 'padres', loadChildren: () => import('./padres/padres.module').then(m => m.PadresModule) },

  // Shared routes
  { path: 'ayuda', component: AyudaComponent, data: { num: 4 } },

  { path: '**', component: HomeComponent, canActivate: [LoggedInAuthGuard] },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})

export class AppRoutingModule { }
