import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import { map } from 'rxjs/operators';
import { BehaviorSubject, Observable } from 'rxjs';
import { Globals } from '../classes/globals';
import { Role } from '../_models/Role';
import { User } from '../classes/user';
import { SnackbarService } from './notifications/snackbar.service';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {
  // variable to store the currentUsersSubject BehaviorSubject
  private currentUserSubject: BehaviorSubject<any>;
  // variable to store the currentUser observable
  public currentUser: Observable<any>;
  // variable that store the import of Globals Class that will help us to retrieve the urls for every service
  globals = Globals;
  baseUrl = `${this.globals.urlCuantrixBase}${this.globals.users.pathUsers}`;

  constructor(private http: HttpClient, private router: Router, private httpClient: HttpClient, private snackbarService: SnackbarService) {
    this.currentUserSubject = new BehaviorSubject<any>(JSON.parse(localStorage.getItem('currentUser')));
    this.currentUser = this.currentUserSubject.asObservable();
  }
  // Validate if the currentUserSubject exists, if exists get its value.
  public get currentUserValue() {
    if (this.currentUserSubject && this.isAuthenticated()) {
      return this.currentUserSubject.value;
    } else {
      this.currentUserSubject.next(null);
      return false;
    }
  }

  // Verifies if user is logged in
  isAuthenticated(): boolean {
    const token = localStorage.getItem('currentUser');
    if (token) {
      return true;
    }
  }

  // method to identify if the user is already logged and its a teacher.
  isATeacher(): void {
    if (this.currentUserValue && this.currentUserValue.role === Role.Teacher) {
      this.router.navigate(['/maestro/dashboard']);
    }
  }

  // method to identify if the user is already logged and its a student.
  isAStudent(): void {
    if (this.currentUserValue && this.currentUserValue.role === Role.Kid) {
      this.router.navigate(['/alumno/bienvenido']);
    }
  }

  /**
   * Cuantrix login - store the token in the local storage.
   * @param username Username of the user
   * @param password Password of the user
   * @param type Type of login (teacher or student)
   */
  login(username: string, password: string, type: Role) {
    const loginUrl = `${this.baseUrl}${this.globals.users.login}`;

    return this.http.post<any>(loginUrl, {
      username,
      password,
      user_type: type
    })
      .pipe(
        map(result => {
          // login successful if there's a jwt token in the response
          if (result && result.content.token) {
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            const user = new User(result);
            localStorage.setItem('currentUser', JSON.stringify(user));
            this.currentUserSubject.next(user);
          }
          return result;
        }));
  }

  /**
  * Update teacher data by id given
  * @param id - Identifier of a teacher
  */
  async changePassword(passwords: object) {
    return await this.http.post<any>(`${this.baseUrl}${this.globals.users.changePassword}`, passwords).toPromise();
  }

  // Cuantrix logout - remove the token of the local storage.
  logout(userType: string) {
    const currentUser = localStorage.getItem('currentUser');
    if (currentUser) {
      this.handleDestroyToken();
      // remove user from local storage to log user out
      localStorage.removeItem('currentUser');
      this.currentUserSubject.next(null);
      this.router.navigateByUrl('/');
      return true;
    }
  }

  // method to handle the responses of the request to destroy a token
  handleDestroyToken(): void {
    this.destroyToken().then(result => {
      if (result['error'] === false) {
        this.snackbarService.showSnackBar('Cerrar', `${result['message']}. 👋`);
      } else {
        this.snackbarService.showSnackBar('Cerrar', 'Ha ocurrido un error. ⚠️');
      }
    }).catch(error => this.snackbarService.showSnackBar('Cerrar', 'Ha ocurrido un error. ⚠️'));
  }

  // method to destroy the token and make it invalid
  async destroyToken(): Promise<object> {
    return await this.httpClient.post(`${this.baseUrl}${this.globals.users.logout}`, {}).toPromise();
  }
}
