<div class="container pt-4 privacy-advice">
    <app-aviso-privacidad></app-aviso-privacidad>
    <div class="row mt-0 mb-3">
        <div class="col-12 col-md-4 options">
            <button class="btn btn-exit" [routerLink]="[ '/']" animateTag animateType="bounce">
            Salir
          </button>
        </div>
    </div>
</div>
