import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import * as firebase from 'firebase/app';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  // chevron right icon
  faChevron = faChevronRight;

  constructor(
    private router: Router
  ) { }

  ngOnInit(): void {
  }

  // navigate to parent modules
  goToParentModule() {
    // tslint:disable-next-line: no-unused-expression
    (environment.production === true) && firebase.analytics().logEvent('getsIntoParentModule');
    this.router.navigateByUrl('/padres');
  }

}
