import { environment } from './../../environments/environment';
export const Globals = {
  urlCuantrixBase: environment.apiUrl,
  users: {
    pathUsers: 'users/',
    login: 'login/',
    logout: 'logout/',
    validate: 'validate_username/',
    changePassword: 'change_password/',
    validateEmail: 'validate_email/',
  },
  teachers: {
    pathTeachers: 'teachers/',
    login: 'teachers/login',
    create: 'register/',
    validateEmail: 'validate_email/',
    forgotPassword: 'forgot_password/',
    resetPassword: 'reset_password/',
    events: '/events/',
    monthSchedule: '/month_schedule/',
    daySchedule: '/day_schedule/'
  },
  schedule: {
    pathSchedule: 'schedule_lesson/',
  },
  students: {
    pathStudents: 'kids/',
    validateCode: 'validate_code/',
    register: 'register/',
    getByCode: 'bycode/',
    lessonDone: 'finish_lesson/',
    forgotPassword: 'forgot_password/',
    resetPassword: 'reset_password/',
    active: 'active/',
    finish_session: 'finish_session/',
    resetByCode: 'reset_password_by_code/',
    survey: '/survey_attempt/'
  },
  groups: {
    pathGroups: 'groups/',
    removeStudent: 'remove_student/',
    studentsProgress: '/students_progress/',
    lessons: '/lessons/',
    surveys: '/survey_students/'
  },
  grades: {
    pathGrades: 'grades/',
    lessons: 'lessons/',
    types: 'school_types/',
    survey: 'surveys/'
  },
  schools: {
    pathSchools: 'schools',
  },
  keys: {
    pathKey: 'kids/',
    generate: 'generate_code/',
  },
  states: {
    pathStates: 'states/',
  },
  parents: {
    pathParents: 'parents/',
    validateEmail: 'validate_email/',
    resendEmail: 'resend_email/',
    parentValidateCode: 'validate_code/',
  },

};
