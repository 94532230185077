<div class="help">
    <div class="container">
        <div class="row">
            <div class="col-12 box marginTop">
                <div class="row">
                    <div class="col-12 d-flex justify-content-center marginTop titles-section">
                        <span class="title red">¿Necesitas ayuda?</span>
                        <span class="pl-0 pl-md-3 title">escríbenos a:</span>
                    </div>
                </div>

                <div class="row mt-5 justify-content-center">
                    <div class="col-md-8 col-lg-6 text-center">
                        <a href="mailto:cuantrix.contacto@fundaciontelevisa.org" class="btn btn-email w-100">
                            <span class="pr-2">
                <fa-icon [icon]="faArrow"></fa-icon>
              </span> cuantrix.contacto@fundaciontelevisa.org
                        </a>
                    </div>
                </div>
                <div class="row imgs mb-5">
                    <div class="col-10 offset-1">
                        <p class="paragraph">
                            ¿Tienes dudas sobre el programa? ¿Quieres saber la mejor manera de usar nuestros manuales? ¿Has encontrado fallas o problemas técnicos en la plataforma? ¿Quieres sugerirnos tus ideas?
                            <span class="mt-3 d-block">¡Estaremos encantados de escucharte!</span>
                        </p>
                    </div>
                </div>

                <div class="row justify-content-center mb-5">
                    <div class="col-8 col-md-4 text-center">
                        <button class="btn btn-exit" (click)="back()" animateTag animateType="bounce">
              Salir
            </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>