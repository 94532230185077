import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-privacy-advice',
  templateUrl: './privacy-advice.component.html',
  styleUrls: ['./privacy-advice.component.scss']
})
export class PrivacyAdviceComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
