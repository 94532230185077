<div class="home">
    <section class="container-fluid home__container d-flex flex-column justify-content-md-center">
        <div class="row home__title">
            <div class="col-md-12 text-center">
                <h2 class="home__text mt-md-0 mt-4"> <span class="home__letter--color">¡</span>Hola<span class="home__letter--color">!</span>, Bienvenido a</h2>
                <img class="logo" src="../../../assets/img/logo.png" alt="" height="100px">
            </div>
        </div>
        <div class="row mt-md-3 mt-0">
            <div class="col-lg-12 col-md-12 text-center">
                <div>
                    <img src="../../../assets/img/Inicio-alumnos.png" class="rocket__img desktop" alt="">
                    <button animateTag animateType="bounce" routerLink="/alumno" class="btn btn__iniciar">
                      <img src="../../../assets/img/Inicio-alumnos.png" class="rocket__img mobile" alt="">
                        <span class="btn__iniciar--padding">Niños</span>
                        <fa-icon [icon]="faChevron" class="chevron-right right"></fa-icon>
                    </button>
                </div>
            </div>
        </div>
        <div class="row mt-lg-5">
            <div class="col-lg-6 col-md-12 mt-lg-3 text-center">
                <button (click)="goToParentModule()" class="btn btn__padres" animateTag animateType="bounce">
                    <img src="../../../assets/img/Inicio-padres.png" class="mr-3" alt="">
                    Padres</button>
            </div>
            <div class="col-lg-6 col-md-12 mt-lg-3 text-center">
                <button routerLink="/maestro" class="btn btn__padres" animateTag animateType="bounce">
                    <img src="../../../assets/img/Inicio-maestros.png" class="mr-3" alt="">
                    Maestros</button>
            </div>
        </div>
        <div class="row home__link">
            <div class="col-12">
                <p class="mt-5">
                    Consulta nuestro
                    <a [routerLink]="[ '/aviso-privacidad']">aviso de privacidad</a>
                </p>
            </div>
        </div>
    </section>
</div>
